import React from 'react';

const Logo = () => {
    return (
        <div className="py-4">
            <h1 className="text-base font-normal text-white text-center mb-8">
                RESILIENCIA
            </h1>
        </div>
    );
};

export default Logo;