import React from 'react';
import Logo from './components/Logo';
import CardGenerator from './components/CardGenerator';

function App() {
  return (
    <div className="min-h-screen bg-[#0A0A0A] text-white flex flex-col items-center justify-between p-4 pb-safe">

      <Logo />
      <CardGenerator />

      <footer className="mt-8">
        <div className="w-full text-center py-4 text-sm">

          <p>
            Hecho con amor por{' '}
            <a
              href="https://resiliencia.app/"
              target="_blank"
              rel="noopener noreferrer"
              className="underline hover:text-gray-300 transition-colors"
            >
              RESILIENCIA
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
