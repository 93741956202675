import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabaseClient';
import DailyCard from './DailyCard';
import Fingerprint2 from 'fingerprintjs2';

const CardGenerator = () => {
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [cardNumber, setCardNumber] = useState(null);
    const [cardDate, setCardDate] = useState(null);
    const [fingerprint, setFingerprint] = useState(null);
    const [title, setTitle] = useState(null);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [totalCards, setTotalCards] = useState(0);

    useEffect(() => {
        generateFingerprint();
        fetchTotalCards();
    }, []);

    const generateFingerprint = () => {
        Fingerprint2.get((components) => {
            const values = components.map(component => component.value);
            const fp = Fingerprint2.x64hash128(values.join(''), 31);
            setFingerprint(fp);
        });
    };

    const fetchTotalCards = async () => {
        try {
            const { data, error } = await supabase
                .from('card_counter')
                .select('total_cards')
                .single();

            if (error) throw error;

            setTotalCards(data.total_cards);
        } catch (error) {
            console.error('Error fetching total cards:', error);
        }
    };

    const checkDailyCard = async () => {
        const today = new Date().toISOString().split('T')[0];

        // Check local storage first
        const storedCard = localStorage.getItem('dailyCard');
        if (storedCard) {
            const { message, cardNumber, date, title } = JSON.parse(storedCard);
            if (date === today) {
                setMessage(message);
                setCardNumber(cardNumber);
                setCardDate(date);
                setTitle(title);
                return true; // Card exists for today
            } else {
                localStorage.removeItem('dailyCard');
            }
        }

        // If not in local storage, check the database
        try {
            const { data: existingCard } = await supabase
                .from('generated_cards')
                .select('*')
                .eq('fingerprint', fingerprint)
                .eq('created_at', today)
                .single();

            if (existingCard) {
                setMessage(existingCard.message);
                setCardNumber(existingCard.card_number);
                setCardDate(today);
                setTitle(existingCard.title);
                // Update local storage
                localStorage.setItem('dailyCard', JSON.stringify({
                    message: existingCard.message,
                    cardNumber: existingCard.card_number,
                    date: today,
                    title: existingCard.title
                }));
                return true; // Card exists for today
            }
        } catch (error) {
            console.error('Error checking for existing card:', error);
        }

        return false; // No card exists for today
    };

    const generateCard = async () => {
        if (!fingerprint) {
            setError('Unable to generate card at this time. Please try again later.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const today = new Date().toISOString().split('T')[0];

            // Fetch all messages with their titles
            const { data: messages, error: messagesError } = await supabase
                .from('messages')
                .select('content, title');

            if (messagesError) throw messagesError;

            if (!messages || messages.length === 0) {
                throw new Error('No messages found in the database');
            }

            // Select a random message
            const randomIndex = Math.floor(Math.random() * messages.length);
            const randomMessage = messages[randomIndex].content;
            const randomTitle = messages[randomIndex].title;

            // Increment the total card count and get the new value
            const { data: counterData, error: counterError } = await supabase
                .rpc('increment_card_counter');

            if (counterError) throw counterError;

            const newCardNumber = counterData[0].new_count;

            // Update total cards
            setTotalCards(newCardNumber);

            // Store the card data in the database
            await supabase.from('generated_cards').insert({
                fingerprint: fingerprint,
                message: randomMessage,
                card_number: newCardNumber,
                title: randomTitle,
            });

            // Store the card data in local storage
            const cardData = {
                message: randomMessage,
                cardNumber: newCardNumber,
                date: today,
                title: randomTitle
            };
            localStorage.setItem('dailyCard', JSON.stringify(cardData));

            setMessage(randomMessage);
            setCardNumber(newCardNumber);
            setCardDate(today);
            setTitle(randomTitle);

        } catch (error) {
            console.error('Error generating card:', error);
            setError('Failed to generate card. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    };

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleGenerateCard = async () => {
        if (!email) {
            setEmailError('Por favor, ingresa tu correo electrónico.');
            return;
        }

        if (!validateEmail(email)) {
            setEmailError('Por favor, ingresa un correo electrónico válido.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            // Store the email in the email_subscriptions table
            const { error: emailError } = await supabase
                .from('email_subscriptions')
                .upsert({ email: email }, { onConflict: 'email' });

            if (emailError) throw emailError;

            // Check for existing card
            const cardExists = await checkDailyCard();

            if (!cardExists) {
                // Generate a new card only if one doesn't exist
                await generateCard();
            }
        } catch (error) {
            console.error('Error storing email or generating card:', error);
            setError('Failed to generate card. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center max-w-md justify-center h-[100%] text-white px-4">
            {!message ? (
                <>
                    <h2 className="text-2xl font-bold mb-4 text-center">Tu carta diaria</h2>
                    <p className="text-center mb-6 max-w-md">
                        Nada es casualidad. La carta que manifiestes contiene un mensaje que va a impulsar tu día. Utiliza su mensaje para crear aquello que desees.
                    </p>
                    <blockquote className=" italic text-white mb-6 text-center">
                        "El destino es el que baraja las cartas, pero nosotros somos los que jugamos." -William Shakespeare
                    </blockquote>
                    <div className="w-full max-w-md mb-4">
                        <div className="flex gap-4 flex-col items-center">
                            <input
                                type="email"
                                placeholder="Tu correo electrónico"
                                value={email}
                                onChange={handleEmailChange}
                                className="flex-grow mr-2 p-2 border w-full roundedborder-gray-600 rounded bg-gray-800 outline-none"
                                required
                            />
                            {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
                            <button
                                onClick={handleGenerateCard}
                                disabled={loading}
                                className={`bg-white w-full hover:bg-gray-800 text-black hover:text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center ${loading ? 'opacity-75 cursor-not-allowed' : ''
                                    }`}
                                type="button"
                            >
                                {loading ? (
                                    <>
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Generando...
                                    </>
                                ) : (
                                    'Generar Carta'
                                )}
                            </button>
                            <p className="text-sm text-gray-400 mt-2">
                                Total de cartas generadas: {totalCards}
                            </p>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <h2 className="text-xl font-bold mb-4 text-center">Una carta al día</h2>
                    <p className="text-center mb-6 text-sm">
                        Tu corazón sabe el camino. Tu intuición sabe como utilizar este mensaje.💚
                    </p>
                    <div className="animate-fade-in mt-4">
                        <DailyCard
                            message={message}
                            date={cardDate}
                            cardNumber={cardNumber}
                            title={title}
                        />
                    </div>
                    <p className="text-sm text-gray-400 mt-4">
                        Total de cartas generadas: {totalCards}
                    </p>
                </>
            )}
            {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
        </div>
    );
};

export default CardGenerator;