import React, { useState, useRef, useEffect } from 'react';
import html2canvas from 'html2canvas';

const DailyCard = ({ message, date, cardNumber, title }) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [cardSize, setCardSize] = useState({ width: '4in', height: '5.33in' }); // Larger default size
    const [isInstagramBrowser, setIsInstagramBrowser] = useState(false);
    const cardRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) { // Mobile breakpoint
                setCardSize({ width: '90vw', height: '120vw' });
            } else {
                // Larger size for desktop
                setCardSize({ width: '4in', height: '5.33in' });
            }
        };

        handleResize(); // Set initial size
        window.addEventListener('resize', handleResize);

        // Check if it's Instagram's in-app browser
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        setIsInstagramBrowser(userAgent.indexOf('Instagram') > -1);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Updated date formatting
    const dateObj = new Date(date);
    const day = dateObj.getDate().toString().padStart(2, '0');
    const monthNames = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
    const month = monthNames[dateObj.getMonth()];
    const year = dateObj.getFullYear().toString().slice(-2);
    const formattedDate = `${day}/${month}/${year}`;

    const handleClick = () => {
        setIsFlipped(!isFlipped);
    };

    const saveCard = async () => {
        if (cardRef.current) {
            try {
                const canvas = await html2canvas(cardRef.current, {
                    backgroundColor: null,
                    scale: 2, // Increase resolution
                    logging: true, // Enable logging for debugging
                });

                // Create a new canvas with the same dimensions
                const newCanvas = document.createElement('canvas');
                newCanvas.width = canvas.width;
                newCanvas.height = canvas.height;
                const ctx = newCanvas.getContext('2d');

                // Clear the entire canvas to make it transparent
                ctx.clearRect(0, 0, newCanvas.width, newCanvas.height);

                // Draw rounded rectangle (card shape)
                ctx.beginPath();
                ctx.moveTo(0, 20);
                ctx.arcTo(0, 0, 20, 0, 20);
                ctx.lineTo(canvas.width - 20, 0);
                ctx.arcTo(canvas.width, 0, canvas.width, 20, 20);
                ctx.lineTo(canvas.width, canvas.height - 20);
                ctx.arcTo(canvas.width, canvas.height, canvas.width - 20, canvas.height, 20);
                ctx.lineTo(20, canvas.height);
                ctx.arcTo(0, canvas.height, 0, canvas.height - 20, 20);
                ctx.closePath();

                // Create a clipping region
                ctx.save();
                ctx.clip();

                // Draw the original canvas content within the clipping region
                ctx.drawImage(canvas, 0, 0);

                // Restore the context (removes clipping)
                ctx.restore();

                const image = newCanvas.toDataURL("image/png");

                if (navigator.share) {
                    try {
                        const blob = await (await fetch(image)).blob();
                        const file = new File([blob], `resiliencia-card-${cardNumber}.png`, { type: 'image/png' });
                        await navigator.share({
                            files: [file],
                            title: 'Resiliencia Card',
                            text: 'Check out my Resiliencia card!',
                        });
                    } catch (error) {
                        console.error('Error sharing:', error);
                        // Fallback to download if sharing fails
                        downloadImage(image);
                    }
                } else {
                    // Fallback for browsers that don't support Web Share API
                    downloadImage(image);
                }
            } catch (error) {
                console.error('Error saving card:', error);
            }
        }
    };

    const downloadImage = (image) => {
        const link = document.createElement('a');
        link.download = `resiliencia-card-${cardNumber}.png`;
        link.href = image;
        link.click();
    };

    return (
        <div className="flex flex-col items-center">
            <div
                ref={cardRef}
                className={`card-container ${isFlipped ? 'flipped' : ''}`}
                onClick={handleClick}
                style={{
                    width: cardSize.width,
                    height: cardSize.height,
                    perspective: '1000px',
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                }}
            >
                <div className="card" style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    transformStyle: 'preserve-3d',
                    transition: 'transform 0.6s',
                }}>
                    <div className="card-back" style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backfaceVisibility: 'hidden',
                        backgroundColor: 'black',
                        border: '1px solid white',
                        borderRadius: '0.5rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        fontSize: '16px',
                        fontWeight: 'normal'
                    }}>
                        "RESILIENCIA"
                    </div>
                    <div className="card-front bg-white text-black rounded-lg shadow-lg border border-black flex flex-col justify-between" style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backfaceVisibility: 'hidden',
                        transform: 'rotateY(180deg)',
                        padding: '3%',
                        boxSizing: 'border-box',
                    }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            fontSize: '10px',
                            marginBottom: '2%',
                            position: 'relative',
                        }}>
                            <span>{formattedDate}</span>
                            <span style={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                fontSize: '10px',
                                fontWeight: 'normal',
                            }}>"RESILIENCIA"</span>
                            <span>#{cardNumber}</span>
                        </div>
                        <div style={{
                            fontSize: window.innerWidth <= 768 ? '12px' : '10px',
                            textAlign: 'center',
                            flex: '1',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: window.innerWidth <= 768 ? '0 4%' : '0 8%', // Less padding on mobile
                            margin: '2% 0',
                        }}>
                            {message}
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '10px',
                            marginTop: '2%',
                        }}>
                            <span>{title}</span>
                        </div>
                    </div>
                </div>
            </div>
            {isInstagramBrowser ? (
                <p className="mt-4 text-center text-sm">
                    Para guardar o compartir tu carta, por favor abre esta página en un navegador web regular o simplemente haz un pantallazo.
                </p>
            ) : (
                <button
                    onClick={saveCard}
                    className="mt-4 bg-white hover:bg-gray-200 text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    style={{ fontSize: 'clamp(0.8rem, 2vw, 1rem)' }}
                >
                    Guardar o Compartir
                </button>
            )}
        </div>
    );
};

export default DailyCard;